import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import { Helmet } from "react-helmet";
import { Select } from "../components/Core";

import imgCC from "../assets/image/inner/credit-cards.png";
import imgV from "../assets/image/inner/visa-image.png";
import imgP from "../assets/image/inner/paypal-brand.png";

const kontakpenipu = [
  { value: "LINE", label: "LINE" },
  { value: "WhatsApp", label: "WhatsApp" },
  { value: "Facebook", label: "Facebook" },
  { value: "Twitter", label: "Twitter" },
  { value: "Instagram", label: "Instagram" },
  { value: "SitusWeb", label: "Situs Web" },
  { value: "Lainnya", label: "Lainnya" },
];

const produk = [
  { value: "Mainan dan Hobi", label: "Mainan dan Hobi" },
  { value: "Produk Digital", label: "Produk Digital" },
  { value: "Olahraga dan Outdoor", label: "Olahraga dan Outdoor" },
  { value: "Perlengkapan Bayi dan Anak", label: "Perlengkapan Bayi dan Anak" },
  { value: "Jam dan Arloji", label: "Jam dan Arloji" },
  { value: "Obat-Obatan", label: "Obat-Obatan" },
  { value: "Perlengkapan Rumah Tangga", label: "Perlengkapan Rumah Tangga" },
  { value: "Penipuan Berhadiah", label: "Penipuan Berhadiah" },
  { value: "Tiket Event atau Wisata", label: "Tiket Event atau Wisata" },
  { value: "Fashion dan Kecantikan", label: "Fashion dan Kecantikan" },
  { value: "Pulsa atau Paket Internet", label: "Pulsa atau Paket Internet" },
  { value: "Investasi", label: "Investasi" },
  { value: "Makanan atau Minuman", label: " Makanan atau Minuman" },
  { value: "Kendaraan", label: "Kendaraan" },
  { value: "Barang Elektronik", label: "Barang Elektronik" },
  { value: "Lainnya", label: "Lainnya" },
];

const sosmed = [
  { value: "Facebook", label: "Facebook" },
  { value: "Twitter", label: "Twitter" },
  { value: "Instagram", label: "Instagram" },
];

const Kirim = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      > 
      <Helmet>
        <title>Laporkan Penipuan - Ethical Hacker Indonesia</title>  
        <meta name="description" content="Kamu pernah menjadi korban penipuan online? Laporkan sekarang kepada kami dan kami akan membantu mencarikan informasi dan juga data tentang penipu tersebut. Informasi yang kami dapatkan akan kami bagikan kepada kamu melalui email yan kamu lampirkan." />
        <meta name="image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta property="og:url" content="https://hack.co.id/kirim/" />
        <meta property="og:title" content="Laporkan Penipuan" />
        <meta property="og:description" content="Kamu pernah menjadi korban penipuan online? Laporkan sekarang kepada kami dan kami akan membantu mencarikan informasi dan juga data tentang penipu tersebut. Informasi yang kami dapatkan akan kami bagikan kepada kamu melalui email yan kamu lampirkan." />
        <meta property="og:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@EthicalHackID" />
        <meta name="twitter:title" content="Laporkan Penipuan"  />
        <meta name="twitter:description" content="Kamu pernah menjadi korban penipuan online? Laporkan sekarang kepada kami dan kami akan membantu mencarikan informasi dan juga data tentang penipu tersebut. Informasi yang kami dapatkan akan kami bagikan kepada kamu melalui email yan kamu lampirkan." />
        <meta name="twitter:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
      </Helmet>
        <div className="inner-banner pt-29 pb-6">  
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">
                    Laporkan Penipuan
                  </h2>
                  <p className="gr-text-8 mb-13">
                    Saat ini laporan untuk penipuan sedang ditutup ya.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        
      </PageWrapper>
    </>
  );
};
export default Kirim;
